function ready(callback: () => void) {
  if (document.readyState !== "loading") {
    callback();
  } else {
    document.addEventListener("DOMContentLoaded", callback);
  }
}

export default function navToggle() {
  ready(() => {
    const header = document.querySelector("header");
    const button = header.querySelector(".nav-toggle");

    if (button) {
      button.addEventListener("click", (evt: Event) => {
        evt.preventDefault();
        header.classList.toggle("show-nav");
      });
    }
  });
}
